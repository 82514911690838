const Blockchain = ({ width, height, color }) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={width} height={height} viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill={color} stroke="none">
            <path d="M2043 4772 l-513 -297 0 -512 0 -513 75 0 75 0 0 469 0 468 436 252
            c240 138 441 251 447 251 7 0 206 -112 442 -249 l430 -248 5 -469 5 -469 75 0
            75 0 0 509 0 510 -515 298 c-283 164 -517 297 -520 297 -3 0 -236 -134 -517
            -297z"/>
            <path d="M2505 4749 c-27 -18 -51 -33 -53 -34 -1 -1 14 -30 34 -64 27 -46 41
            -60 53 -56 9 4 18 7 21 8 3 1 99 -52 213 -117 114 -65 209 -117 210 -115 24
            31 67 114 64 123 -4 10 -443 268 -478 280 -8 3 -36 -8 -64 -25z"/>
            <path d="M2288 4624 l-56 -35 36 -62 c20 -34 37 -63 38 -65 3 -6 124 69 124
            78 0 17 -64 120 -75 119 -5 0 -36 -16 -67 -35z"/>
            <path d="M2087 4506 c-31 -18 -59 -35 -63 -38 -7 -8 58 -128 69 -128 9 0 92
            46 114 63 10 7 6 20 -14 56 -50 87 -42 83 -106 47z"/>
            <path d="M1804 4314 c-21 -37 -34 -69 -28 -71 5 -2 167 -94 359 -206 l350
            -202 3 -418 2 -417 75 0 75 0 0 418 0 417 28 16 c273 157 678 392 681 395 6 5
            -12 44 -43 93 l-25 40 -348 -201 c-191 -111 -355 -203 -364 -205 -8 -3 -69 27
            -135 65 -65 38 -209 121 -319 184 -110 64 -217 125 -237 137 l-36 22 -38 -67z"/>
            <path d="M3045 3603 c-33 -19 -62 -37 -64 -39 -2 -2 31 -64 73 -138 l77 -135
            -30 -41 c-42 -58 -58 -136 -43 -208 14 -68 107 -234 159 -283 57 -55 98 -72
            186 -77 63 -4 80 -8 91 -24 13 -17 11 -25 -13 -61 -37 -56 -51 -100 -51 -162
            0 -66 12 -99 73 -205 69 -119 87 -142 148 -175 45 -24 67 -30 129 -31 l75 -2
            72 -124 c40 -67 74 -127 77 -133 3 -8 136 52 136 62 0 2 -34 63 -76 136 -74
            129 -75 132 -59 157 66 101 77 208 32 298 -115 229 -191 295 -337 294 -62 -1
            -68 1 -79 24 -9 21 -7 30 18 64 16 22 35 63 41 91 20 84 7 142 -61 263 -95
            168 -146 207 -282 214 l-77 4 -42 72 c-22 39 -57 99 -77 132 l-37 62 -59 -35z
            m364 -409 c37 -31 131 -199 131 -234 0 -14 -4 -31 -9 -39 -7 -11 -13 -7 -25
            18 -9 17 -21 31 -28 31 -19 0 -118 -61 -118 -73 0 -7 7 -21 15 -31 21 -28 19
            -38 -7 -31 -39 12 -68 46 -119 140 -46 86 -62 146 -40 159 5 3 18 -10 30 -29
            l20 -34 65 37 65 37 -19 33 c-11 18 -20 35 -20 38 0 13 32 1 59 -22z m374
            -654 c27 -22 114 -165 127 -211 5 -16 5 -41 1 -56 l-7 -27 -23 37 -23 37 -31
            -18 c-18 -11 -46 -27 -64 -37 l-32 -18 20 -40 20 -39 -23 7 c-38 12 -66 44
            -119 136 -37 65 -49 96 -49 128 0 50 11 52 35 6 l19 -34 56 31 c74 42 74 41
            56 76 -9 17 -16 33 -16 37 0 12 29 4 53 -15z"/>
            <path d="M1939 3500 l-74 -130 -71 0 c-55 0 -81 -5 -120 -25 -69 -34 -103 -73
            -171 -195 l-58 -104 0 -92 c0 -85 2 -94 33 -144 28 -45 32 -58 23 -76 -9 -21
            -17 -23 -80 -23 -78 0 -134 -19 -189 -66 -38 -31 -136 -193 -159 -261 -28 -83
            -7 -200 49 -260 19 -21 18 -22 -57 -151 -41 -71 -75 -134 -75 -140 0 -11 101
            -73 119 -73 5 0 43 60 84 133 l75 132 50 -3 c70 -5 149 20 200 63 52 45 157
            228 172 300 15 74 -10 172 -58 228 -12 14 -13 22 -4 42 11 23 17 25 80 25 57
            0 80 5 130 31 74 36 94 60 168 189 48 86 57 110 62 165 6 77 -10 141 -48 191
            l-27 37 73 128 c41 71 74 133 74 138 0 10 -101 71 -118 71 -5 0 -43 -58 -83
            -130z m-181 -316 c-23 -40 -24 -39 53 -82 l56 -32 19 35 c25 46 34 44 34 -7 0
            -55 -94 -224 -138 -250 -47 -28 -57 -22 -32 19 17 29 19 38 8 44 -7 5 -36 22
            -65 38 l-51 30 -24 -37 -23 -37 -3 47 c-3 40 4 58 49 138 33 59 64 99 83 111
            48 29 58 24 34 -17z m-377 -660 c-12 -20 -21 -38 -19 -39 2 -1 31 -18 65 -38
            l62 -36 22 37 c21 36 22 37 30 14 13 -34 2 -66 -54 -166 -32 -56 -62 -95 -81
            -107 -48 -29 -58 -24 -34 17 24 40 23 40 -53 83 l-56 31 -18 -34 c-23 -42 -35
            -38 -35 11 0 50 94 219 135 245 49 29 60 23 36 -18z"/>
            <path d="M2185 2989 c-20 -34 -32 -65 -28 -69 17 -16 395 -230 405 -230 13 0
            376 207 397 226 12 11 9 22 -19 71 -18 32 -34 60 -36 62 -1 2 -76 -39 -166
            -90 -89 -52 -166 -95 -170 -97 -4 -2 -82 40 -173 92 -91 53 -167 96 -170 96
            -2 0 -20 -28 -40 -61z"/>
            <path d="M443 2102 l-443 -257 0 -596 0 -597 513 -296 c283 -163 517 -296 521
            -296 14 0 946 542 946 550 0 14 -64 120 -72 120 -4 0 -194 -107 -420 -238
            -227 -132 -423 -244 -435 -250 -19 -10 -71 17 -460 242 l-438 253 -3 510 -2
            511 410 237 410 237 -34 61 c-20 34 -39 63 -43 64 -4 1 -207 -114 -450 -255z"/>
            <path d="M4191 2288 c-30 -54 -32 -62 -18 -73 8 -6 191 -112 406 -236 l391
            -224 0 -510 0 -511 -431 -249 c-238 -138 -438 -251 -446 -253 -7 -2 -205 107
            -440 243 -234 135 -429 242 -433 238 -4 -4 -21 -33 -39 -65 l-31 -56 112 -65
            c62 -36 273 -158 468 -271 l355 -205 67 37 c36 20 269 154 517 297 l451 260
            -3 599 -2 600 -439 253 c-242 139 -442 253 -445 253 -4 0 -22 -28 -40 -62z"/>
            <path d="M1626 2014 c-21 -36 -36 -67 -34 -68 2 -2 76 -45 166 -96 l162 -93 0
            -168 0 -169 75 0 75 0 0 210 0 210 -37 24 c-21 13 -99 59 -173 101 -74 43
            -149 86 -166 96 l-30 18 -38 -65z"/>
            <path d="M3247 1953 l-197 -115 2 -202 3 -201 72 -3 73 -3 2 162 3 162 160 93
            c88 51 161 97 163 103 5 14 -60 121 -73 120 -5 0 -99 -53 -208 -116z"/>
            <path d="M276 1688 c-31 -56 -34 -64 -20 -75 9 -6 171 -101 360 -210 l344
            -198 0 -418 0 -418 73 3 72 3 3 415 2 416 359 207 360 208 -36 62 c-20 34 -37
            63 -38 65 -1 1 -163 -90 -360 -203 -196 -114 -363 -204 -371 -201 -7 3 -161
            90 -341 194 -181 104 -338 194 -350 201 -20 10 -24 6 -57 -51z"/>
            <path d="M3327 1678 l-36 -63 57 -32 c31 -18 192 -110 357 -206 l300 -173 3
            -417 2 -417 75 0 75 0 0 414 0 414 138 80 c75 44 236 137 357 207 121 70 222
            129 224 130 2 2 -14 31 -34 65 -25 41 -42 59 -52 56 -7 -3 -167 -94 -355 -203
            -187 -108 -345 -196 -352 -196 -6 0 -168 91 -360 201 -192 111 -352 202 -357
            202 -4 0 -23 -28 -42 -62z"/>
            <path d="M250 1375 l0 -75 75 0 75 0 0 75 0 75 -75 0 -75 0 0 -75z"/>
            <path d="M4720 1345 l0 -75 75 0 75 0 0 75 0 75 -75 0 -75 0 0 -75z"/>
            <path d="M2145 1311 c-114 -7 -159 -25 -215 -82 -20 -21 -45 -56 -55 -78 l-18
            -41 -159 0 -158 0 0 -75 0 -75 158 0 157 0 29 -55 c30 -59 80 -104 143 -132
            30 -14 67 -18 178 -17 125 0 146 2 192 23 60 27 125 91 144 144 11 32 17 37
            44 37 26 0 34 -6 49 -36 31 -65 67 -103 129 -134 58 -29 65 -30 197 -30 168 0
            214 13 281 80 25 26 52 63 60 83 l13 37 158 0 158 0 0 75 0 75 -155 0 -155 0
            -24 48 c-28 55 -88 114 -143 139 -30 14 -67 17 -183 18 -139 0 -147 -1 -202
            -28 -63 -31 -121 -91 -138 -143 -10 -28 -17 -34 -41 -34 -24 0 -32 7 -53 48
            -47 91 -129 146 -236 154 -30 3 -100 2 -155 -1z m948 -154 c18 -8 38 -21 46
            -30 12 -15 9 -17 -33 -17 l-46 0 0 -74 0 -75 48 -3 47 -3 -35 -22 c-31 -21
            -48 -23 -157 -23 -115 0 -123 1 -150 25 l-28 24 43 1 42 0 0 75 0 75 -40 0
            c-22 0 -40 4 -40 9 0 11 39 37 65 44 44 11 207 7 238 -6z m-726 -22 l28 -24
            -47 -1 -48 0 0 -75 0 -75 47 0 47 0 -20 -21 c-27 -26 -80 -38 -169 -39 -84 0
            -136 13 -160 40 -18 20 -17 20 23 20 l42 0 0 75 0 75 -42 0 -43 1 28 24 c27
            24 34 25 157 25 123 0 130 -1 157 -25z"/>
            <path d="M250 1125 l0 -75 75 0 75 0 0 75 0 75 -75 0 -75 0 0 -75z"/>
            <path d="M4720 1095 l0 -75 75 0 75 0 0 75 0 75 -75 0 -75 0 0 -75z"/>
            <path d="M250 873 l1 -78 274 -157 274 -156 36 65 c20 35 35 66 33 67 -1 2
            -108 63 -235 137 -230 131 -233 134 -233 166 l0 33 -75 0 -75 0 0 -77z"/>
            <path d="M4718 898 c-2 -17 -51 -50 -210 -142 l-208 -120 31 -55 c17 -31 35
            -60 39 -65 5 -5 117 54 254 134 l246 142 0 64 0 64 -74 0 c-69 0 -75 -2 -78
            -22z"/>
            </g>
        </svg>
    )
}

export default Blockchain