const Devops = ({ width, height, color }) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={width} height={height} viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill={color} stroke="none">
            <path d="M1151 4614 c-93 -47 -145 -154 -122 -254 5 -24 9 -45 8 -45 -1 -1
            -40 -14 -87 -29 -47 -15 -131 -52 -188 -81 -383 -199 -638 -534 -734 -966 -30
            -135 -32 -395 -5 -532 101 -505 461 -915 941 -1072 310 -101 653 -89 961 34
            225 91 458 269 590 455 22 30 42 55 45 55 3 0 23 -25 45 -57 107 -151 347
            -350 451 -373 145 -33 284 79 284 227 0 93 -36 153 -130 217 -211 143 -356
            365 -399 611 -16 87 -13 256 5 344 45 218 188 438 371 568 362 259 865 218
            1182 -95 267 -263 348 -663 206 -1012 -58 -144 -175 -293 -303 -388 -64 -46
            -200 -121 -222 -121 -5 0 -10 13 -10 28 0 98 -85 201 -186 223 -100 22 -193
            -17 -248 -105 -69 -109 -196 -355 -206 -400 -11 -52 0 -124 27 -165 19 -29
            225 -285 262 -324 89 -97 253 -97 342 0 45 48 63 93 61 158 0 33 -2 68 -4 78
            -2 14 15 23 84 47 48 16 123 47 166 69 639 321 935 1050 700 1720 -35 101
            -111 251 -138 276 -43 39 -120 7 -120 -50 0 -13 22 -67 49 -121 100 -199 136
            -350 136 -574 -1 -171 -15 -262 -67 -410 -143 -407 -503 -723 -923 -810 -31
            -7 -65 -20 -76 -31 -26 -26 -24 -72 6 -107 40 -48 49 -85 30 -122 -9 -17 -28
            -35 -42 -41 -55 -21 -75 -5 -208 157 -69 86 -130 166 -135 180 -12 32 -6 47
            101 245 91 167 109 189 160 189 31 0 79 -53 79 -87 0 -15 -11 -48 -25 -72 -44
            -77 -23 -141 48 -141 47 0 170 36 253 74 415 192 672 638 624 1083 -87 811
            -991 1238 -1665 788 -224 -149 -385 -381 -451 -650 -8 -33 -19 -131 -24 -217
            -12 -200 -25 -280 -62 -398 -59 -186 -153 -343 -288 -484 -268 -280 -619 -415
            -1005 -385 -659 52 -1170 626 -1142 1284 14 321 132 599 350 824 170 174 367
            287 606 347 103 26 131 46 131 93 0 15 -14 43 -32 65 -40 48 -43 94 -8 131 17
            18 34 25 60 25 43 0 59 -15 210 -204 83 -103 104 -135 104 -160 -1 -35 -172
            -360 -206 -388 -27 -24 -83 -23 -108 2 -34 34 -36 67 -6 127 34 70 34 105 -1
            133 -34 26 -72 22 -199 -20 -606 -205 -898 -889 -627 -1471 46 -99 83 -120
            138 -78 34 28 31 61 -19 179 -51 122 -69 214 -69 355 0 358 192 663 520 829
            40 20 75 36 78 36 3 0 5 -15 5 -32 3 -208 278 -308 411 -149 45 53 217 378
            228 431 12 58 -2 127 -37 178 -15 22 -81 105 -146 186 -97 119 -129 151 -170
            171 -69 35 -144 34 -215 -1z m1715 -2301 c65 -85 161 -176 244 -233 33 -22 64
            -49 70 -60 35 -65 -24 -144 -95 -126 -66 17 -269 197 -363 322 -81 108 -79
            101 -43 187 17 41 35 87 41 103 11 28 11 28 51 -46 22 -41 65 -107 95 -147z"/>
            <path d="M3570 4353 c-131 -13 -307 -63 -439 -124 -183 -85 -393 -253 -513
            -410 -29 -38 -56 -69 -59 -69 -4 0 -19 19 -34 42 -42 63 -200 222 -281 283
            -112 84 -157 105 -228 105 -244 -1 -324 -293 -121 -437 70 -49 113 -55 145
            -21 39 42 26 86 -39 130 -25 18 -51 39 -58 47 -19 25 -15 76 8 105 34 43 81
            36 170 -25 95 -67 252 -225 308 -311 48 -75 48 -60 -2 -183 -13 -33 -27 -69
            -31 -80 -6 -16 -16 -4 -51 64 -24 47 -53 93 -64 104 -43 44 -125 11 -124 -50
            0 -15 17 -55 37 -88 153 -252 168 -614 35 -878 -123 -243 -329 -412 -595 -489
            -68 -20 -101 -23 -239 -22 -144 0 -169 3 -248 27 -114 35 -224 91 -317 161
            -40 31 -83 56 -96 56 -32 0 -63 -30 -70 -68 -6 -29 -2 -37 37 -74 98 -94 270
            -184 428 -225 130 -33 319 -42 448 -19 422 73 762 389 864 801 12 47 24 147
            30 245 11 184 35 322 79 441 146 401 496 702 922 795 113 25 323 29 443 10
            237 -39 459 -145 637 -303 42 -37 55 -44 85 -41 45 4 79 44 69 82 -23 91 -368
            307 -591 370 -165 47 -379 66 -545 49z"/>
            <path d="M809 1091 l-24 -19 0 -277 0 -277 24 -19 c20 -16 40 -19 135 -19 100
            0 117 3 164 26 116 58 178 201 152 352 -20 117 -72 191 -160 231 -64 29 -256
            30 -291 2z m267 -164 c55 -64 51 -209 -7 -267 -27 -26 -38 -30 -85 -30 l-54 0
            0 166 0 166 62 -4 c50 -4 65 -9 84 -31z"/>
            <path d="M1445 1085 l-25 -24 0 -264 c0 -340 -13 -317 179 -317 125 0 140 2
            156 20 25 28 23 76 -4 103 -18 18 -35 23 -101 25 l-80 4 0 44 0 44 73 0 c64 0
            76 3 95 23 29 31 28 72 -3 102 -21 22 -33 25 -95 25 l-70 0 0 45 0 45 70 0
            c38 0 80 5 94 11 51 23 55 103 7 128 -11 6 -76 11 -146 11 -120 0 -127 -1
            -150 -25z"/>
            <path d="M1955 1085 c-14 -13 -25 -32 -25 -42 0 -34 173 -518 193 -540 28 -32
            86 -31 115 0 27 28 182 485 182 534 0 62 -76 97 -119 54 -10 -10 -41 -85 -67
            -165 -27 -80 -51 -146 -55 -146 -4 0 -29 64 -55 143 -28 83 -58 151 -71 165
            -29 29 -68 28 -98 -3z"/>
            <path d="M2734 1089 c-68 -26 -138 -89 -172 -153 -23 -44 -27 -63 -27 -141 0
            -78 4 -97 27 -141 34 -65 114 -138 175 -158 60 -21 157 -20 219 1 34 11 67 35
            111 78 75 75 98 138 91 244 -15 208 -230 345 -424 270z m211 -164 c126 -95 58
            -295 -100 -295 -57 0 -102 24 -139 73 -33 44 -30 142 6 189 57 74 160 89 233
            33z"/>
            <path d="M3342 1087 c-21 -23 -22 -30 -22 -295 0 -259 1 -273 20 -292 27 -27
            81 -26 106 1 15 16 20 40 23 105 l3 84 49 0 c190 0 289 217 161 354 -48 51
            -95 66 -213 66 -98 0 -107 -2 -127 -23z m226 -143 c26 -18 29 -60 5 -86 -11
            -12 -30 -18 -60 -18 l-43 0 0 60 0 60 38 0 c21 0 48 -7 60 -16z"/>
            <path d="M4015 1091 c-74 -34 -115 -95 -115 -172 0 -82 46 -129 177 -179 44
            -17 88 -39 98 -50 16 -17 15 -20 -4 -39 -15 -15 -34 -21 -63 -21 -40 0 -56 7
            -121 53 -19 13 -35 17 -56 12 -36 -8 -49 -22 -57 -64 -6 -28 -2 -37 38 -75 54
            -51 119 -76 200 -76 131 0 221 81 221 197 0 96 -54 149 -210 208 -40 15 -73
            32 -73 39 0 42 75 48 135 11 51 -31 80 -31 110 0 36 35 32 71 -10 111 -64 60
            -191 81 -270 45z"/>
            </g>
        </svg>
    )
}

export default Devops