import React from 'react'

const ButtonMagic = ({ content, className }) => {
  return (
    <button className={`relative w-full inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none ${className}`}>
        <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#2A388F_0%,#FA931C_50%,#2A388F_100%)]" />
        <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-3 py-1 text-sm font-medium text-white backdrop-blur-3xl">
            {content}
        </span>
    </button>
  )
}

export default ButtonMagic