const Server = ({ height, color, width }) => { 
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={width} height={height} viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill={color} stroke="none">
            <path d="M2410 4954 c-363 -47 -651 -180 -896 -416 -109 -105 -187 -200 -252
            -308 l-39 -65 -129 -7 c-75 -4 -161 -15 -205 -27 -448 -117 -749 -522 -726
            -976 22 -426 306 -773 726 -887 64 -18 114 -22 314 -26 l237 -4 0 -135 c0 -77
            5 -153 12 -177 23 -78 135 -166 212 -166 14 0 16 -19 16 -160 l0 -160 -443 0
            -443 0 -24 52 c-29 64 -89 126 -155 158 -71 35 -197 35 -270 0 -64 -31 -124
            -91 -155 -155 -35 -72 -35 -198 0 -270 31 -64 91 -124 155 -155 41 -20 66 -24
            135 -24 73 0 92 4 136 26 77 40 108 71 145 143 l34 65 498 0 498 0 24 25 c25
            24 25 24 25 240 l0 215 80 0 80 0 0 -320 0 -320 -335 0 -336 0 -24 -25 c-24
            -23 -25 -29 -25 -162 l0 -138 -68 -36 c-234 -123 -225 -452 15 -570 39 -19 64
            -24 133 -24 78 0 90 3 148 34 70 38 101 70 140 145 22 43 26 64 27 136 0 78
            -3 90 -34 148 -38 71 -82 112 -153 144 l-48 22 0 83 0 83 335 0 336 0 24 25
            25 24 0 376 0 375 160 0 160 0 0 -402 0 -403 -68 -36 c-233 -123 -226 -452 13
            -569 41 -20 65 -25 135 -25 78 0 90 3 148 34 70 38 101 70 140 145 22 43 26
            64 27 136 0 78 -3 90 -34 148 -38 71 -82 112 -153 144 l-48 22 0 403 0 403
            160 0 160 0 0 -375 0 -376 25 -24 24 -25 336 0 335 0 0 -82 0 -83 -68 -36
            c-234 -123 -225 -452 15 -570 39 -19 64 -24 133 -24 78 0 90 3 148 34 70 38
            101 70 140 145 22 43 26 64 27 136 0 78 -3 90 -34 148 -38 71 -82 112 -153
            144 l-48 22 0 138 c0 134 -1 140 -25 163 l-24 25 -336 0 -335 0 0 320 0 320
            80 0 80 0 0 -215 c0 -216 0 -216 25 -240 l24 -25 499 0 498 0 24 -52 c29 -64
            89 -126 155 -158 41 -20 65 -25 135 -25 77 1 91 4 148 34 70 38 100 69 140
            145 23 44 27 63 27 136 -1 100 -24 160 -90 225 -65 66 -125 89 -225 90 -78 0
            -90 -3 -148 -34 -71 -38 -112 -82 -144 -154 l-22 -47 -443 0 -443 0 0 160 c0
            148 1 160 18 160 48 0 113 34 155 80 57 64 67 101 67 264 l0 136 274 0 c195 0
            295 4 348 14 350 68 606 342 651 697 37 292 -107 594 -361 760 -135 89 -258
            123 -435 124 l-127 0 -26 80 c-74 225 -193 416 -373 596 -226 226 -470 358
            -777 419 -97 20 -364 34 -444 24z m425 -182 c508 -110 902 -484 1040 -987 35
            -128 51 -139 169 -116 441 85 833 -318 741 -761 -50 -240 -253 -444 -490 -493
            -88 -18 -635 -22 -635 -4 0 6 -9 24 -21 40 -20 29 -20 29 -1 57 37 52 42 90
            42 292 0 202 -5 240 -42 293 -19 27 -19 27 0 55 10 15 24 42 30 61 16 47 16
            410 0 465 -16 55 -99 138 -154 154 -59 17 -1849 17 -1908 0 -55 -16 -138 -99
            -154 -154 -16 -55 -16 -418 0 -465 6 -19 20 -46 30 -61 19 -28 19 -28 0 -55
            -37 -53 -42 -91 -42 -293 0 -202 5 -240 42 -292 19 -28 19 -28 -1 -57 -12 -16
            -21 -34 -21 -41 0 -19 -458 -8 -530 13 -213 61 -368 172 -481 345 -88 134
            -124 258 -124 432 0 229 78 415 237 570 166 162 382 240 623 226 126 -7 131
            -6 175 84 46 92 148 228 240 320 205 206 463 337 757 385 119 19 357 13 478
            -13z m660 -1117 c25 -24 25 -26 25 -215 0 -189 0 -191 -25 -215 l-24 -25 -911
            0 -911 0 -24 25 c-25 24 -25 26 -25 215 0 189 0 191 25 215 l24 25 911 0 911
            0 24 -25z m0 -640 c25 -24 25 -26 25 -215 0 -189 0 -191 -25 -215 l-24 -25
            -911 0 -911 0 -24 25 c-25 24 -25 26 -25 215 0 189 0 191 25 215 l24 25 911 0
            911 0 24 -25z m0 -640 c25 -24 25 -26 25 -215 0 -189 0 -191 -25 -215 l-24
            -25 -911 0 -911 0 -24 25 c-25 24 -25 26 -25 215 0 189 0 191 25 215 l24 25
            911 0 911 0 24 -25z m-2952 -869 c103 -43 128 -177 48 -257 -65 -65 -157 -65
            -222 0 -124 123 13 325 174 257z m4160 0 c103 -43 128 -177 48 -257 -65 -65
            -157 -65 -222 0 -124 123 13 325 174 257z m-2079 -720 c44 -18 86 -75 93 -124
            20 -152 -160 -241 -268 -133 -124 124 13 325 175 257z m-1200 -160 c44 -18 86
            -75 93 -124 20 -152 -160 -241 -268 -133 -124 124 13 325 175 257z m2400 0
            c44 -18 86 -75 93 -124 20 -152 -160 -241 -268 -133 -124 124 13 325 175 257z"/>
            <path d="M1760 3440 l0 -80 480 0 480 0 0 80 0 80 -480 0 -480 0 0 -80z"/>
            <path d="M2880 3440 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
            <path d="M3200 3440 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
            <path d="M1760 2800 l0 -80 480 0 480 0 0 80 0 80 -480 0 -480 0 0 -80z"/>
            <path d="M2880 2800 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
            <path d="M3200 2800 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
            <path d="M1760 2160 l0 -80 480 0 480 0 0 80 0 80 -480 0 -480 0 0 -80z"/>
            <path d="M2880 2160 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
            <path d="M3200 2160 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
            </g>
        </svg>
    )
}

export default Server