import React, { Suspense, lazy } from 'react'
import Hero from './hero/Hero'
import InfiniteScroll from '../../components/infinite-scroll/InfiniteScroll'
// import About_Section from './About_Section'
// import Faq from '../../components/faq/Faq'
import CTA from '../../components/cta/CTA'
import Thumbnail from './bradview-video-thumnail.png'

// Lazy load the VideoContainer component
const VideoContainer = lazy(() => import('../../components/video/VideoContainer'));

const Home = () => {
  return (
    <div>
        <Hero />
        <div className='bts-spacer-x2'></div>
        {/* Wrap the lazy loaded component in Suspense to handle loading state */}
        <Suspense fallback={<div>Loading video...</div>}>
          {/* <VideoContainer 
            videoId="QmsJ9OfngnY"
            title="Broadview Tech Website Presentation Video"
          /> */}
          <VideoContainer 
            videoId="QmsJ9OfngnY"
            title="Broadview Tech Website Presentation Video"
            facadeImage={Thumbnail} // Replace with your image URL
          />
        </Suspense>
        {/* <About_Section /> */}
        <InfiniteScroll height='40rem' />
        {/* <Faq /> */}
        <CTA />
    </div>
  )
}

export default Home;
