const tabData = [
    // {
    //     "title": "All",
    //     "url": "/portfolio"
    // },
    {
        "title": "Web Design",
        "url": "web-design"
    },
    {
        "title": "UI/UX Design",
        "url": "ui-ux-design"
    },
    {
        "title": "Development",
        "url": "development"
    },
    {
        "title": "Graphics Design",
        "url": "graphics-design"
    }
]

export default tabData