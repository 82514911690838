const MobileDev = ({ width, height, color }) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={width} height={height} viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill={color} stroke="none">
            <path d="M1586 4949 c-62 -15 -153 -68 -197 -116 -22 -24 -55 -74 -72 -111
            l-32 -67 -3 -889 -3 -888 -47 7 c-59 8 -155 59 -188 98 -14 16 -37 53 -52 81
            -22 43 -26 64 -26 136 0 74 4 92 28 140 16 30 56 81 89 112 55 53 59 60 53 90
            -8 42 -39 68 -82 68 -27 0 -45 -11 -97 -60 -71 -66 -118 -144 -142 -235 -69
            -268 117 -545 396 -589 l69 -10 0 -238 0 -238 -34 0 c-39 0 -36 -5 -62 117
            -19 95 -34 112 -114 138 -30 10 -91 35 -135 56 -51 25 -87 37 -100 33 -11 -4
            -54 -29 -96 -55 -42 -27 -80 -49 -85 -49 -4 0 -29 21 -55 46 l-47 46 50 77
            c75 114 75 111 30 205 -22 45 -49 111 -61 149 -13 37 -30 71 -39 76 -10 5 -61
            19 -114 31 l-98 22 0 68 0 68 97 22 c53 12 105 27 115 34 10 6 28 40 39 76 12
            36 39 101 61 146 45 94 45 91 -30 205 l-50 77 47 46 c26 25 51 46 55 46 5 0
            43 -22 85 -49 42 -26 85 -51 96 -55 13 -4 49 8 100 33 44 21 110 48 146 60 44
            14 70 29 78 44 22 42 70 289 61 313 -11 28 -48 54 -77 54 -48 0 -72 -38 -95
            -151 l-21 -106 -88 -37 -88 -37 -94 61 c-59 37 -105 60 -123 60 -23 0 -50 -22
            -146 -118 -96 -96 -118 -123 -118 -147 0 -18 22 -64 61 -124 l60 -94 -29 -66
            c-17 -36 -33 -75 -36 -87 -5 -17 -22 -24 -89 -38 -45 -9 -97 -22 -114 -27 -58
            -18 -63 -36 -63 -209 0 -205 -5 -198 176 -236 l80 -17 38 -88 37 -88 -61 -94
            c-37 -59 -60 -105 -60 -123 0 -23 22 -50 118 -146 96 -96 123 -118 146 -118
            18 0 64 23 123 60 l94 61 88 -37 88 -37 21 -106 c29 -139 41 -151 152 -151
            l79 0 3 -808 3 -808 37 -76 c45 -91 103 -147 196 -191 l67 -32 975 0 975 0 67
            32 c93 44 151 100 196 191 l37 76 3 889 3 889 46 -7 c53 -8 125 -41 170 -79
            16 -14 45 -53 64 -88 30 -56 33 -71 34 -143 0 -71 -4 -88 -33 -147 -22 -45
            -48 -80 -82 -108 -28 -23 -53 -52 -56 -65 -7 -27 18 -80 41 -89 46 -17 74 -6
            138 54 71 66 118 144 142 235 69 268 -117 545 -396 589 l-69 10 0 238 0 238
            34 0 c39 0 36 5 62 -117 19 -95 34 -112 114 -138 30 -10 91 -35 135 -56 51
            -25 87 -37 100 -33 11 4 54 29 96 55 42 27 80 49 85 49 11 0 94 -82 94 -94 0
            -5 -22 -43 -49 -85 -26 -42 -51 -85 -55 -96 -4 -13 8 -49 33 -100 21 -44 48
            -110 60 -148 13 -37 30 -71 39 -76 10 -5 61 -18 115 -31 l97 -22 0 -68 0 -68
            -97 -22 c-54 -13 -105 -26 -115 -31 -9 -5 -26 -39 -39 -76 -12 -38 -39 -104
            -61 -149 -45 -94 -45 -91 30 -205 l50 -77 -47 -46 c-26 -25 -51 -46 -55 -46
            -5 0 -43 22 -85 49 -42 26 -85 51 -96 55 -13 4 -49 -8 -100 -33 -44 -21 -110
            -48 -146 -60 -44 -14 -70 -29 -78 -44 -22 -42 -70 -289 -61 -313 11 -28 48
            -54 77 -54 48 0 72 38 95 152 18 90 26 109 43 114 11 3 50 19 87 36 l66 30 95
            -61 c59 -39 104 -61 122 -61 24 0 51 22 147 118 96 96 118 123 118 147 0 18
            -22 63 -60 123 l-61 94 38 89 c24 56 43 89 53 89 8 0 58 11 109 24 133 33 131
            30 131 226 0 196 2 193 -131 226 -51 13 -101 24 -109 24 -10 0 -29 33 -53 89
            l-38 90 61 94 c37 59 60 105 60 123 0 23 -22 50 -118 146 -96 96 -123 118
            -146 118 -18 0 -64 -23 -124 -61 l-95 -61 -66 30 c-36 17 -75 33 -86 36 -17 5
            -25 24 -43 114 -29 140 -41 152 -152 152 l-79 0 -3 808 -3 808 -37 76 c-45 91
            -103 147 -196 191 l-67 32 -955 2 c-556 1 -971 -3 -994 -8z m1928 -161 c55
            -16 138 -99 154 -154 18 -60 18 -4088 0 -4148 -16 -55 -99 -138 -154 -154 -59
            -17 -1849 -17 -1908 0 -55 16 -138 99 -154 154 -18 60 -18 4088 0 4148 15 51
            99 137 148 153 53 16 1858 17 1914 1z"/>
            <path d="M2265 4615 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 271
            0 271 0 24 25 c16 15 25 36 25 55 0 19 -9 40 -25 55 l-24 25 -271 0 -271 0
            -24 -25z"/>
            <path d="M1943 2777 c-166 -166 -183 -186 -183 -217 0 -31 17 -51 183 -217
            162 -163 186 -183 215 -183 44 0 82 38 82 81 0 28 -18 51 -142 176 l-142 143
            142 143 c124 125 142 148 142 176 0 43 -38 81 -82 81 -29 0 -53 -20 -215 -183z"/>
            <path d="M2591 2942 c-18 -14 -39 -82 -106 -352 -47 -184 -85 -345 -85 -358 0
            -32 43 -72 78 -72 63 0 67 10 157 370 47 184 85 345 85 358 0 57 -83 92 -129
            54z"/>
            <path d="M2905 2935 c-16 -15 -25 -35 -25 -56 0 -28 18 -51 142 -176 l142
            -143 -142 -143 c-124 -125 -142 -148 -142 -176 0 -43 38 -81 82 -81 29 0 53
            20 215 183 166 166 183 186 183 217 0 31 -17 51 -183 217 -162 163 -186 183
            -215 183 -22 0 -41 -9 -57 -25z"/>
            <path d="M4185 4295 c-49 -48 -24 -128 42 -141 27 -5 37 -1 63 24 20 21 30 40
            30 62 0 41 -39 80 -80 80 -19 0 -40 -9 -55 -25z"/>
            <path d="M4185 3975 c-49 -48 -24 -128 42 -141 27 -5 37 -1 63 24 20 21 30 40
            30 62 0 41 -39 80 -80 80 -19 0 -40 -9 -55 -25z"/>
            <path d="M4185 3655 c-49 -48 -24 -128 42 -141 27 -5 37 -1 63 24 20 21 30 40
            30 62 0 41 -39 80 -80 80 -19 0 -40 -9 -55 -25z"/>
            <path d="M854 1610 c-54 -21 -72 -100 -31 -138 39 -37 98 -28 122 19 21 39 19
            64 -7 93 -27 29 -55 38 -84 26z"/>
            <path d="M854 1290 c-54 -21 -72 -100 -31 -138 39 -37 98 -28 122 19 21 39 19
            64 -7 93 -27 29 -55 38 -84 26z"/>
            <path d="M854 970 c-54 -21 -72 -100 -31 -138 39 -37 98 -28 122 19 21 39 19
            64 -7 93 -27 29 -55 38 -84 26z"/>
            </g>
        </svg>
    )
}

export default MobileDev