const Design = ({ width, height, color }) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={width} height={height} viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill={color} stroke="none">
            <path d="M273 4879 c-71 -27 -155 -106 -190 -177 l-28 -57 0 -1625 0 -1625 33
            -67 c36 -74 95 -132 170 -170 l47 -23 658 -3 657 -3 0 -203 0 -204 -172 -4
            c-169 -3 -174 -4 -226 -32 -126 -70 -173 -223 -106 -347 32 -59 95 -113 152
            -129 25 -6 461 -10 1302 -10 1251 0 1266 1 1310 21 55 25 105 71 133 124 16
            30 22 58 22 115 0 64 -4 82 -27 122 -65 109 -136 138 -340 138 l-138 0 0 205
            0 205 643 2 642 3 57 27 c105 49 177 143 198 259 7 37 9 592 8 1630 -3 1475
            -4 1577 -21 1619 -39 100 -139 193 -237 220 -22 6 -844 10 -2265 9 -2211 0
            -2230 0 -2282 -20z m4573 -124 c30 -19 60 -49 79 -79 l30 -49 3 -1279 2 -1278
            -2397 2 -2398 3 0 1270 0 1270 22 42 c26 48 86 102 134 119 27 10 527 12 2255
            11 l2221 -2 49 -30z m114 -3062 c0 -238 -2 -272 -19 -308 -22 -49 -62 -90
            -116 -119 l-40 -21 -2200 -3 c-2471 -2 -2269 -9 -2350 73 -68 68 -75 103 -75
            397 l0 248 2400 0 2400 0 0 -267z m-1550 -768 l0 -205 -835 0 -835 0 0 205 0
            205 835 0 835 0 0 -205z m448 -345 c44 -27 75 -96 66 -146 -8 -44 -48 -92 -91
            -110 -27 -12 -248 -14 -1275 -14 -1191 0 -1245 1 -1278 19 -102 54 -106 191
            -8 251 32 20 53 20 1293 20 1247 0 1260 0 1293 -20z"/>
            <path d="M2986 4188 c-22 -35 -546 -1456 -546 -1482 0 -52 75 -73 99 -28 23
            43 543 1460 543 1479 1 53 -68 74 -96 31z"/>
            <path d="M4146 3978 c-14 -20 -16 -89 -16 -550 0 -515 1 -529 20 -548 24 -24
            49 -25 76 -6 18 14 19 33 22 547 2 466 1 535 -13 555 -20 32 -68 32 -89 2z"/>
            <path d="M900 3920 c-20 -20 -21 -29 -18 -393 3 -366 3 -373 26 -415 74 -138
            227 -213 374 -183 104 22 187 84 236 176 l27 50 3 360 c2 198 0 370 -3 383 -9
            34 -48 49 -79 28 l-25 -16 -3 -363 -3 -364 -28 -41 c-41 -63 -97 -95 -172
            -100 -82 -5 -128 11 -180 62 -62 62 -65 85 -65 470 0 335 0 335 -22 350 -30
            21 -44 20 -68 -4z"/>
            <path d="M1720 3921 c-5 -11 -10 -27 -10 -37 0 -9 52 -113 116 -232 l116 -215
            -20 -43 c-11 -23 -64 -121 -116 -219 -53 -97 -96 -187 -96 -200 0 -15 10 -31
            26 -42 45 -29 66 -6 170 187 51 96 97 176 102 178 4 1 50 -77 102 -174 104
            -192 125 -217 169 -193 15 9 27 25 29 39 1 16 -38 100 -113 239 -63 117 -115
            218 -115 224 0 6 52 107 115 224 63 117 115 222 115 232 0 31 -29 52 -64 49
            -29 -3 -36 -13 -131 -190 -55 -103 -102 -188 -105 -188 -3 0 -48 80 -100 177
            -52 97 -101 182 -108 190 -20 20 -70 16 -82 -6z"/>
            <path d="M3230 3920 c-20 -20 -21 -29 -18 -388 3 -350 4 -369 24 -413 65 -139
            226 -221 376 -190 104 22 187 84 236 176 l27 50 0 380 0 379 -26 15 c-24 13
            -29 13 -53 -2 l-26 -17 0 -343 c0 -210 -4 -357 -11 -380 -6 -21 -26 -56 -45
            -77 -48 -52 -106 -74 -188 -68 -80 5 -138 41 -176 108 l-25 45 -3 355 c-2 346
            -3 355 -23 373 -28 22 -44 22 -69 -3z"/>
            <path d="M1958 1780 c-152 -46 -175 -262 -36 -340 141 -80 304 42 274 205 -18
            95 -140 165 -238 135z m111 -136 c12 -15 21 -34 21 -44 0 -10 -9 -29 -21 -44
            -40 -51 -129 -20 -129 46 0 65 88 94 129 42z"/>
            <path d="M2508 1780 c-78 -23 -128 -95 -128 -181 0 -155 182 -242 300 -142 84
            70 93 190 22 267 -51 55 -127 76 -194 56z m111 -136 c12 -15 21 -34 21 -44 0
            -10 -9 -29 -21 -44 -40 -51 -129 -20 -129 46 0 65 88 94 129 42z"/>
            <path d="M3045 1775 c-75 -26 -136 -131 -119 -203 20 -84 67 -132 146 -152
            146 -37 273 117 209 255 -22 48 -54 79 -103 100 -45 18 -80 19 -133 0z m120
            -125 c26 -29 24 -79 -4 -102 -50 -39 -121 -9 -121 52 0 62 84 95 125 50z"/>
            </g>
        </svg>
    )
}

export default Design